import React, { useContext } from 'react'
import NavContext from 'components/NavProvider'
import useStoryblokApi from 'hooks/useStoryblokApi'
import useRemark from 'hooks/useRemark'
import Component from './index.js'

export default ({ data, ...props }) => {
  const { lang } = useContext(NavContext)
  const highlights = useStoryblokApi({ by_uuids: data.product[0].component.highlights?.map(hl => hl.highlight).join(',') })
  return (
    <Component
      data={{
        ...data,
        product: [
          {
            ...data.product[0],
            component: {
              ...data.product[0].component,
              highlights: data.product[0].component.highlights?.map(hl => ({ ...hl, highlight: [highlights?.stories?.find(h => h.uuid === hl.highlight)].filter(Boolean).map(h => ({ ...h, component: h.content })) })) || []
            },
            full_slug:
              lang === 'default'
                ? data.product[0].full_slug
                : `${lang}/${data.product[0].full_slug}`,
            storyblokId: data.product.id
          }
        ]
      }}
      {...props}
    />
  )
}
